import React, { useContext } from 'react';
import { AudioPlayerContext } from '../contexts/AudioPlayerState';
const utils = require('../utilities/utils');

/**
 * Play button that appears outside the audio player, such as in episodes list.
 */
export default function BtnPlay({
  showTitle,
  episodeTitle,
  episodeUrl,
  customClasses,
}) {
  const { changeAudioPlayerSrc } = useContext(AudioPlayerContext);
  const contextAudio = useContext(AudioPlayerContext);
  // console.log('[BtnPlay] contextAudio.srcUrl is ', contextAudio.srcUrl);
  // console.log('[BtnPlay] contextAudio: ', contextAudio);

  function playAudio() {
    contextAudio.playerRefState.current.audio.current.play();

    if (utils.localStorageIsAvailable) {
      contextAudio.playerRefState.current.audio.current.playbackRate = localStorage.getItem(
        'playbackRate'
      );
    }
  }

  function handlePlayAudio(url, title) {
    changeAudioPlayerSrc(url, title);
    playAudio();
  }

  return (
    <button
      type="button"
      onClick={(event) =>
        handlePlayAudio(episodeUrl, `${episodeTitle} [${showTitle}]`)
      }
      className={
        !customClasses ? `episodes__btn` : `episodes__btn ${customClasses}`
      }
    >
      PLAY
    </button>
  );
}
