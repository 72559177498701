import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import EpisodesList from '../components/episodes-list';
import SEO from '../components/seo/seo';

// <pre>
// { JSON.stringify(data, null, 2) }
// </pre>

// import logoTest from '../img/logo-radio.svg';
// <img src={logoTest} alt="" width="160" />

/**
 * Home Page
 * @param {object} data GraphQL data
 */
const Home = ({ data }) => {
  const latestEpisodes = data.allMarkdownRemark;
  const showsMeta = data.allShowsMetaJson;

  return (
    <>
      <SEO />
      <Helmet>
        <body className="theme-1 page-home" />
      </Helmet>
      {/* <header className="slogan"> </header>*/}
      <h1 className="slogan__txt">
        A curated hub of web dev podcasts <br />
        with an emphasis on the front end and Jamstack.
      </h1>

      {/* :::: Latest Episodes :::: */}
      <section>
        <h2 className="headingPage">Latest Episodes</h2>
        <EpisodesList
          episodes={latestEpisodes}
          showLogos={true}
          showsMeta={showsMeta}
          isMultiColumn={true}
          classChildren="episodes__item--btn-info-logo"
        />

        <div className="btnWrap">
          <Link to="/podcasts" className="btn btn--lg">
            Explore All Podcasts
          </Link>
        </div>
      </section>

      {/* :::: More Info :::: */}
      <aside className="info">
        <h2 className="info__heading">
          Dev Shows is updated daily.
          <span>
            {showsMeta.edges.length} shows, 6000+ episodes, and counting.
          </span>
        </h2>
        <ul className="info__list">
          {showsMeta.edges.map((podcast) => {
            return (
              <li key={podcast.node.id} className="info__item">
                <Link
                  to={`/podcasts/${podcast.node.id}/`}
                  className="info__link"
                >
                  {podcast.node.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </aside>
    </>
  );
};

export default Home;

export const homepageQuery = graphql`
  query latestEpisodesQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___pubDate }
      limit: 30
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            pubDate(formatString: "MMMM D, YYYY")
            showId
            summary
            url
          }
        }
      }
    }

    allShowsMetaJson {
      edges {
        node {
          id
          title
          logo {
            childImageSharp {
              fixed(width: 76, height: 76) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;

// --------------- SCRAPS ----------------

// import Layout from "../components/layout";
// import AudioPlayer from 'react-h5-audio-player';
// import 'react-h5-audio-player/lib/styles.css';

// import latest from "../../json/shows-latest-episodes.json";

// const JSONbuildtime = () => (
//   <div style={{ maxWidth: `960px`, margin: `1.45rem` }}>
//     <h1>{JSONData.title}</h1>
//     <ul>
//       {JSONData.content.map((data, index) => {
//         return <li key={`content_item_${index}`}>{data.item}</li>
//       })}
//     </ul>
//   </div>
// )
// export default JSONbuildtime;

// export default () => (
//   <div>Hello world</div>
// );

// export default function Home() {
//   return (
//     <div>Hello world!!</div>
//   )
// }
