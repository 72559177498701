import React from 'react';
import { Link } from 'gatsby';
import BtnPlay from '../components/btn-play';
import Img from 'gatsby-image';
const utils = require('../utilities/utils');

// <pre>
// { JSON.stringify(showsMeta, null, 2) }
// </pre>

/**
 * Display list of episodes
 * @param {array} episodes
 * @param {Boolean} showLogos Whether to display the show logo for each episode in list
 */
export default function EpisodesList({
  episodes,
  showLogos = false,
  showPlayBtn = true,
  showSummary = false,
  showsMeta,
  isMultiColumn = false,
  classParent = '',
  classChildren = '',
}) {
  // showsMeta can either contain an array of show objects or be a single object, depending on context of when EpisodesList is used. For ex, it's an array from the Homepage but a single object from a show landing page.
  const moreThanOneShow = showsMeta.edges ? true : false;
  const meta = moreThanOneShow ? showsMeta.edges : showsMeta;

  return (
    <ul
      className={
        isMultiColumn
          ? `episodes episodes--multicolumn ${classParent}`
          : `episodes ${classParent}`
      }
    >
      {episodes.edges.map((episode) => {
        const epId = episode.node.id;
        const ep = episode.node.frontmatter;
        let show;
        let showMeta = [];

        // level-out show var so it is always an object
        if (moreThanOneShow) {
          showMeta = meta.find((show) => show.node.id === ep.showId);
          show = showMeta.node;
        } else {
          show = meta;
        }

        // <em style={{fontWeight: 'normal', textAlign: 'right'}}>The Web Platform Podcast</em>
        return (
          <li key={epId} className={`episodes__item ${classChildren}`}>
            <div className="episodes__info">
              <time dateTime="{ep.pubDate}" className="episodes__date">
                {ep.pubDate}
              </time>
              {moreThanOneShow && (
                <span className="episodes__show">
                  <cite>{show.title}</cite>
                </span>
              )}

              <Link
                to={`/podcasts/${ep.showId}/${utils.createEpisodeFoldername(
                  ep.title
                )}`}
                className="episodes__link"
              >
                {ep.title}
              </Link>

              {showSummary && ep.summary !== 'undefined' && <p>{ep.summary}</p>}
            </div>

            {showPlayBtn && (
              <BtnPlay
                showTitle={show.title}
                episodeTitle={ep.title}
                episodeUrl={ep.url}
              />
            )}

            {showLogos && (
              <Link to={`/podcasts/${ep.showId}/`} className="episodes__logo">
                {show.logo.childImageSharp.fixed && (
                  <Img
                    fixed={show.logo.childImageSharp.fixed}
                    alt={ep.showId}
                    className="show__img"
                  />
                )}
                {show.logo.childImageSharp.fluid && (
                  <Img
                    fluid={show.logo.childImageSharp.fluid}
                    alt={ep.showId}
                    className="show__img"
                  />
                )}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}
